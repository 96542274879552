<div class="pagination-container pull-right" *ngIf="pagination">
  <div class="pagination h3-reg">
    @if (canPrevious) {
      <span class="previous cursor-pointer">
        <app-action-btn
          [active]="false"
          IconType="caret_left"
          (click)="onPrevious()"
        ></app-action-btn>
      </span>
    }
    @if (state == "default") {
      <div>
        <input
          class="input-wrap"
          type="text"
          placeholder=""
          [value]="current"
          (input)="onInputChanged()"
          (keyup.enter)="isValidInput(current)"
          [(ngModel)]="current"
          [style.width.px]="inputWidth"
        />
      </div>
      of {{ total }}
    }
    @if (state == "locked") {
      <span class="d-flex align-items-center spacing-4"
        ><app-counter [count]="current" [size]="'large'"></app-counter>of
        {{ total }}</span
      >
    }
    @if (canNext) {
      <span class="next cursor-pointer">
        <app-action-btn
          [active]="false"
          IconType="caret_right"
          (click)="onNext()"
        ></app-action-btn>
      </span>
    }
  </div>
</div>
