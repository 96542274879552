import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkMenu, CdkMenuItem } from '@angular/cdk/menu';
import { DropdownSinglePieceComponent } from '@enginuity/core/molecules/dropdown-single-piece/dropdown-single-piece.component';
import { LinkBtnComponent } from '@enginuity/core/molecules/tertiary/link-btn.component';
import { PaginationComponent } from '@enginuity/core/organisms/pagination/pagination.component';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { PagerComponent } from '@enginuity/core/organisms/pager/pager.component';
import { Pagination } from '@services/core-services/models';
import { Selection } from '@services/core-services/models/selection';
import { FooterAction } from '@services/core-services/models/footer-action';
import { ListFooterActionComponent } from '@enginuity/core/organisms/list-footer-action/list-footer-action.component';

@Component({
  selector: 'app-list-footer',
  standalone: true,
  imports: [
    CdkMenu,
    CdkMenuItem,
    DropdownSinglePieceComponent,
    LinkBtnComponent,
    PaginationComponent,
    AsyncPipe,
    PagerComponent,
    NgIf,
    NgForOf,
    ListFooterActionComponent,
  ],
  templateUrl: './list-footer.component.html',
  styleUrl: './list-footer.component.scss',
})
export class ListFooterComponent {
  @Input() pagination: Pagination | undefined | null;
  @Input() selection: Selection<any> | undefined | null;
  @Input() actions: FooterAction[] | null = [];

  @Output() onPageChange = new EventEmitter<number>();
  @Output() onSelectAll = new EventEmitter();
  @Output() onDeselectAll = new EventEmitter();
  @Output() onAction: EventEmitter<FooterAction> = new EventEmitter<FooterAction>();

  get selectAllLabel() {
    return `Select all ${this.pagination?.total_records}`;
  }

  get selectedAllLabel() {
    return `Selected all ${this.pagination?.total_records}`;
  }

  get page() {
    const { next_page, previous_page } = this.pagination || {};
    if (previous_page) return previous_page + 1;
    if (next_page) return next_page - 1;
    return 1;
  }

  get limit() {
    const { limit = 1, total_records = 1 } = this.pagination || {};
    return limit > total_records ? total_records : limit;
  }

  get from() {
    return (this.page - 1) * this.limit! + 1;
  }

  get to() {
    const limit = this.from + this.limit - 1;
    const total = this.pagination?.total_records || 0;
    return limit > total ? total : limit;
  }

  changePage(page: number) {
    this.onPageChange.emit(page);
  }

  selectAll() {
    this.onSelectAll.emit();
  }

  deselectAll() {
    this.onDeselectAll.emit();
  }

  onActionClick(action: FooterAction) {
    this.onAction.emit(action);
  }
}
