import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionBtnComponent } from '../../molecules/action-btn/action-btn.component';
import { CounterComponent } from '../../molecules/counter/counter.component';
import { InputsComponent } from '../../molecules/inputs/inputs.component';
import { FormsModule } from '@angular/forms';
import { Pagination } from '@services/core-services/models';

@Component({
  selector: 'app-pager',
  standalone: true,
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss'],
  imports: [CommonModule, ActionBtnComponent, CounterComponent, InputsComponent, FormsModule],
})
export class PagerComponent {
  private _pagination!: Pagination | undefined | null;
  @ViewChild('hiddenSpan') hiddenSpan!: ElementRef<HTMLElement>;
  @Output() onChange = new EventEmitter<number>();

  @Input()
  state: 'default' | 'locked' = 'default';

  @Input()
  current: any = 1;

  @Input()
  total: number = 5;

  get pagination(): Pagination | undefined | null {
    return this._pagination;
  }

  @Input()
  set pagination(pagination: Pagination | undefined | null) {
    this._pagination = pagination;
    if (pagination) {
      const { total_pages } = pagination;
      this.current = this.gerCurrentPage(pagination);
      this.total = total_pages || 1;
    }
  }

  get canPrevious() {
    return this.pagination?.previous_page;
  }

  get canNext() {
    return this.pagination?.next_page;
  }

  public inputWidth = 25;

  onInputChanged() {
    if (this.current.trim() === '') {
      this.inputWidth = 25;
    } else {
      const newWidth = this.current.length <= 2 ? 40 : (this.current.length + 1) * 8;
      if (newWidth > this.inputWidth) {
        this.inputWidth = newWidth;
      }
    }
  }

  onPrevious() {
    this.canPrevious && this.onChange.emit(this.pagination?.previous_page);
  }

  onNext() {
    this.canNext && this.onChange.emit(this.pagination?.next_page);
  }

  isValidInput(value: any) {
    if (value <= this.total && value !== 0) {
      this.current = value;
      this.onChange.emit(this.current);
    }
  }

  private gerCurrentPage(pagination: Pagination) {
    const { next_page, previous_page } = pagination;
    if (previous_page) return previous_page + 1;
    if (next_page) return next_page - 1;
    return 1;
  }
}
