<div class="table-footer" *ngIf="pagination?.total_records">
  <div class="table-actions h3-bold horizontal-scroll">
    <!-- Pagination Info -->
    @if (selection?.list?.length) {
      <div class="row-count">
        Selected <span class="count">{{ selection?.list?.length }}</span>
        of
        <span class="total-selected">{{ pagination?.total_records }}</span>
      </div>
      <div class="vertical-line"></div>
    }

    <!-- Selection Info -->
    @if (!selection?.list?.length) {
      <div class="row-count">
        Showing <span class="count">{{ from }}-{{ to }}</span>
        of
        <span class="total-selected">{{ pagination?.total_records }}</span>
      </div>
      <div class="vertical-line"></div>
    }

    <!-- Selected All X -->
    @if (
      !selection?.allSelected && (pagination?.limit || 0) <= (this.selection?.list?.length || 0)
    ) {
      <app-link-btn
        [label]="selectAllLabel"
        [active]="false"
        [IconType]="''"
        (custom_click)="selectAll()"
        size="large"></app-link-btn>
      <div class="vertical-line"></div>
    }

    <!-- Select All X -->
    @if (selection?.allSelected) {
      <app-link-btn
        [label]="selectedAllLabel"
        [active]="false"
        [IconType]="''"
        (custom_click)="deselectAll()"
        size="large"></app-link-btn>
      <div class="vertical-line"></div>
    }

    <!-- Parent Content -->
    <ng-content></ng-content>

    <!-- Footer Actions -->
    @if (selection?.list?.length) {
      <ng-container *ngFor="let action of actions">
        <app-list-footer-action
          [action]="action"
          (onAction)="onActionClick($event)"></app-list-footer-action>
      </ng-container>
    }
  </div>

  <!-- Pagination -->
  <app-pager
    [state]="'default'"
    [pagination]="pagination"
    (onChange)="changePage($event)"></app-pager>
</div>
