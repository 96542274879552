import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LinkBtnComponent } from '@enginuity/core/molecules/tertiary/link-btn.component';
import { FooterAction } from '@services/core-services/models/footer-action';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { DropdownSinglePieceComponent } from '@enginuity/core/molecules/dropdown-single-piece/dropdown-single-piece.component';

@Component({
  selector: 'app-list-footer-action',
  standalone: true,
  imports: [LinkBtnComponent, CdkMenuTrigger, CdkMenu, CdkMenuItem, DropdownSinglePieceComponent],
  templateUrl: './list-footer-action.component.html',
  styleUrl: './list-footer-action.component.scss',
})
export class ListFooterActionComponent {
  @Input() action!: FooterAction;
  @Output() onAction: EventEmitter<FooterAction> = new EventEmitter<FooterAction>();

  onActionClick(action: FooterAction) {
    // if (action.actions) {
    //   console.log(action.actions?.length)
    // }
    this.onAction.emit(action);
    // }
  }

  navigateOnKeydown(e: KeyboardEvent, action: FooterAction) {
    if (e.key === 'Enter') {
      this.onAction.emit(action);
    }
  }
}
