<app-link-btn
  (custom_click)="onActionClick(action)"
  [label]="action.label"
  [active]="action.variant === 'success'"
  [alert]="action.variant === 'alert'"
  size="large"
  [disabled]="!!action.disabled"
  [IconType]="action.icon"
  [cdkMenuTriggerFor]="action.actions?.length ? menu : null"
  cdkConnectedOverlayOrigin></app-link-btn>
<ng-template #menu>
  <div class="bottom-margin" cdkMenu>
    <div class="custom-dropdown radius-4 radius-bottom-left" [class.d-none]="!!action.disabled">
      @for (action of action.actions; track action) {
        <dropdown-single-piece
          class="w-100 cursor-pointer"
          (keydown)="navigateOnKeydown($event, action)"
          (onAction)="onActionClick(action)"
          [label]="action.label"
          [color]="''"
          [type]="'bold'"
          [icon]="true"
          [iconType]="action?.icon || ''"
          [dragDrop]="false"
          [toggle]="false"
          [more]="false"
          [radio]="false"
          cdkMenuItem>
        </dropdown-single-piece>
      }
    </div>
  </div>
</ng-template>
